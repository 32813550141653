/**
 * Enable tab support for dropdown menus.
 */
(function () {
	
	// Fix child menus for touch devices.
	function fixMenuTouchTaps(container) {
		var touchStartFn,
			parentLink = container.querySelectorAll('.menu-item-has-children > a, .page_item_has_children > a');

		if ('ontouchstart' in window) {
			touchStartFn = function (e) {
				var menuItem = this.parentNode;

				if (!menuItem.classList.contains('focus')) {
					e.preventDefault();
					for (var i = 0; i < menuItem.parentNode.children.length; ++i) {
						if ( menuItem === menuItem.parentNode.children[i] ) {
							continue;
						}
						menuItem.parentNode.children[i].classList.remove('focus');
					}
					menuItem.classList.add('focus');
				} else {
					menuItem.classList.remove('focus');
				}
			};

			for (var i = 0; i < parentLink.length; ++i) {
				parentLink[i].addEventListener('touchstart', touchStartFn, false)
			}
		}
	}
	
	
	// Mega Menu
	var containerMegaMenu = document.getElementById('mega-menu-mega_navigation');
	if (containerMegaMenu) {
		containerMegaMenu.setAttribute('class', containerMegaMenu.getAttribute('class') + ' menu-items menu-mega-items');
	}
	
	
	/**
	 * Make dropdown menus keyboard accessible.
	 */
	
	// Primary (Mobile) Menu
	var containerPrimary = document.getElementById('menu-primary');
	if (containerPrimary) {
		var buttonPrimary = document.getElementById('nav-toggle'),
			navPrimary = responsiveNav(".mobile-navigation", {                 // Selector
				transition: 350,                                                // Integer: Speed of the transition, in milliseconds
				customToggle: "#nav-toggle",									// Selector: Specify the ID of a custom toggle
				enableFocus: true,                                              // Boolean: Do we use use 'focus' class in our nav
				enableDropdown: navSettings.dropdown,                           // Boolean: Do we use multi level dropdown
				openDropdown: navSettings.expand,                               // String: Label for opening sub menu
				closeDropdown: navSettings.collapse,                            // String: Label for closing sub menu
				resizeMobile: function () {                                     // Set ARIA for menu toggle button
					buttonPrimary.setAttribute('aria-controls', 'menu-primary');
				},
				resizeDesktop: function () {                                    // Remove ARIA from menu toggle button
					buttonPrimary.removeAttribute('aria-controls');
				},
				open: function () {
					navSecondary.close();
					navQuickLinks.close();
				}
			});
		fixMenuTouchTaps(containerPrimary);
	}
	
	// Secondary Menu
	var containerSecondary = document.getElementById('menu-secondary');
	if (containerSecondary) {
		var buttonSecondary = document.getElementById('secondary-nav-toggle'),
			navSecondary = responsiveNav(".secondary-navigation", {				// Selector
				transition: 350,												// Integer: Speed of the transition, in milliseconds
				customToggle: "#secondary-nav-toggle",							// Selector: Specify the ID of a custom toggle
				enableFocus: true,												// Boolean: Do we use use 'focus' class in our nav
				resizeMobile: function () {										// Set ARIA for menu toggle button
					buttonSecondary.setAttribute('aria-controls', 'menu-secondary');
				},
				resizeDesktop: function () {									// Remove ARIA from menu toggle button
					buttonSecondary.removeAttribute('aria-controls');
				},
				open: function () {
					navPrimary.close();
					navQuickLinks.close();
				}
			});
		
		fixMenuTouchTaps(containerSecondary);
	}
	
	// Quick Links Menu
	var containerQuickLinks = document.getElementById('menu-quicklinks');
	if (containerQuickLinks) {
		var buttonQuickLinks = document.getElementById('quicklinks-nav-toggle'),
			navQuickLinks = responsiveNav(".quicklinks-navigation", {				// Selector
				transition: 350,												// Integer: Speed of the transition, in milliseconds
				customToggle: "#quicklinks-nav-toggle",							// Selector: Specify the ID of a custom toggle
				enableFocus: true,												// Boolean: Do we use use 'focus' class in our nav
				resizeMobile: function () {										// Set ARIA for menu toggle button
					buttonQuickLinks.setAttribute('aria-controls', 'menu-quicklinks');
				},
				resizeDesktop: function () {									// Remove ARIA from menu toggle button
					buttonQuickLinks.removeAttribute('aria-controls');
				},
				open: function () {
					navPrimary.close();
					navSecondary.close();
				}
			});
		
		fixMenuTouchTaps(containerQuickLinks);
	}
	
	// Values Menu
	var containerValues = document.getElementById('menu-values');
	if (containerValues) {
		var buttonValues = document.getElementById('values-nav-toggle'),
			navValues = responsiveNav(".values-navigation", {					// Selector
				transition: 350,												// Integer: Speed of the transition, in milliseconds
				customToggle: "#values-nav-toggle",								// Selector: Specify the ID of a custom toggle
				enableFocus: true,                                              // Boolean: Do we use use 'focus' class in our nav
				enableDropdown: true,                           				// Boolean: Do we use multi level dropdown
				openDropdown: navSettings.expand,                               // String: Label for opening sub menu
				closeDropdown: navSettings.collapse,                            // String: Label for closing sub menu
				resizeMobile: function () {										// Set ARIA for menu toggle button
					buttonValues.setAttribute('aria-controls', 'menu-values');
				},
				resizeDesktop: function () {									// Remove ARIA from menu toggle button
					buttonValues.removeAttribute('aria-controls');
				},
				open: function () {
					navPrimary.close();
					navSecondary.close();
				}
			});
		
		fixMenuTouchTaps(containerValues);
	}
	
	
} )();

/**
 * Skip link focus fix.
 */
(function() {
	var isIe = /(trident|msie)/i.test( navigator.userAgent );

	if ( isIe && document.getElementById && window.addEventListener ) {
		window.addEventListener( 'hashchange', function() {
			var id = location.hash.substring( 1 ),
				element;

			if ( ! ( /^[A-z0-9_-]+$/.test( id ) ) ) {
				return;
			}

			element = document.getElementById( id );

			if ( element ) {
				if ( ! ( /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) ) {
					element.tabIndex = -1;
				}

				element.focus();
			}
		}, false );
	}
})();
